import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import { loadLanguageAsync, i18n } from './setup/i18n-setup.js'
import primeVue from 'primevue/config'
import River from '../assets/presets/river'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import '../assets/css/template.scss'

// Lottie
//import Vue3Lottie from 'vue3-lottie'

// Dot Lottie
import '@dotlottie/player-component';


var vm = createApp({
  render: () => h(App),
})
window.app = vm
vm.use(i18n)
vm.use(router)
vm.use(primeVue, { unstyled: true, pt: River })

router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return loadLanguageAsync(to.meta.lang).then(() => next())
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })
      tag.setAttribute('data-vue-router-controlled', '')
      return tag
    })
    .forEach((tag) => document.head.appendChild(tag))
  next()
})
vm.mount('#app')
