<template>
    <div id="main">
        <metainfo>
            <template v-slot:title="{ content }">{{ content ? `${content}` : "" }}</template>
        </metainfo>
        <router-view />
    </div>
</template>

<script>

export default {
    name: "App",
    data() {
        return {}
    },
}
</script>

<style lang="scss">
</style>
